<template>
  <div class="reviews">
    <div class="title">Кейсы</div>

    <div class="reviews__top" />

    <div class="reviews__blocks">
      <div class="reviews__block">
        <div class="reviews__block__content -link -case-1">
          <a href="https://docs.google.com/presentation/d/1_-Cd56DTiLIZ8GlxTP1qjdyfWGt3sTPa/edit?usp=share_link&ouid=118041837563301081199&rtpof=true&sd=true" target="_blank">
            <span>Субклинический гипотиреоз</span>
            <span>Инсулинорезистентность</span>
            <span>Лишний вес</span>
          </a>
        </div>
      </div>
      <div class="reviews__block">
        <div class="reviews__block__content">
          <div class="reviews__block__avatar">
            <img class="" src="@/assets/img/reviews/1.jpg" alt="">
          </div>
          <div class="reviews__block__text">
            <font-awesome-icon class="preview__text-quote" icon="fa-solid fa-quote-right" />
            Гинеколог направила на&nbsp;сдачу ген. теста. По&nbsp;результатам исследований сказали снизить употребление молочки, из-за частичной непереносимости. Возвратившись домой я&nbsp;поняла, что не&nbsp;знаю чем питаться. Подруга порекомендовала мне обратиться к&nbsp;Любе за&nbsp;составлением рациона. Меню я&nbsp;получила в&nbsp;течение 4х&nbsp;дней, закупилась продуктами и&nbsp;понимаю, что очень вовремя обратилась к&nbsp;нутрициологу. Без потери времени и&nbsp;нервных клеток.
          </div>
        </div>
      </div>
      <div class="reviews__block">
        <div class="reviews__block__content">
          <div class="reviews__block__avatar">
            <img class="" src="@/assets/img/reviews/2.jpg" alt="">
          </div>
          <div class="reviews__block__text">
            <font-awesome-icon class="preview__text-quote" icon="fa-solid fa-quote-right" />
            После сдачи чек-апа узнал что у&nbsp;меня инсулинорезистентность. Необходимо соблюдать низкоуглеводное питание, но&nbsp;в&nbsp;клинике мне толком ничего не&nbsp;объяснили. В&nbsp;файле были не&nbsp;только рецепты но&nbsp;и&nbsp;готовая кулинария с&nbsp;адекватными составами. Это для меня важно, так как работа занимает много времени, не успеваю готовить.
          </div>
        </div>
      </div>
<!--      <div class="reviews__block">
        <div class="reviews__block__content">
          <div class="reviews__block__avatar">
            <img class="" src="@/assets/img/reviews/3.jpg" alt="">
          </div>
          <div class="reviews__block__text">
            <font-awesome-icon class="preview__text-quote" icon="fa-solid fa-quote-right" />
            Эндокринолог прописала мне диету с&nbsp;низким гликемическим индексом, но&nbsp;не&nbsp;дала меню. Поэтому я&nbsp;решила обратиться за&nbsp;помощью к&nbsp;Любе. В&nbsp;итоге получила недельное меню рецептов со&nbsp;ссылками на&nbsp;маркетплейсах и&nbsp;расчётом БЖУ.
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'reviews'
})
</script>

<style lang="scss">
  .reviews {
    margin-bottom: 30px;

    .title {
      text-transform: uppercase;
    }

    &__photo {
      width: 100%;
    }

    &__top {
      height: 55px;
      background: #d3cc71;
      background:$mainYellow;
      margin: -29px -15px -15px;
    }

    &__blocks {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      overflow: auto;
    }

    &__block {
      flex: 1 0 80%;
      background-color: $mainGreyBg;
      padding: 40px 15px 30px;
      text-align: center;

      .fa-heart {
        opacity: .8;
      }

      &:not(:last-child) {
        margin-right: 15px;
      }

      &__content {
        text-align: center;
        //background-color: $mainBlueBg;
        background-color: white;
        //color: white;
        position: relative;
        width: 100%;
        min-height: 200px;
        padding: 50px 15px 15px;
        height: 100%;
        border: 3px solid $mainYellow;

        &.-link {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          a {
            margin-top: -8px;
            display: block;
            color: black;
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            text-decoration: none;
            background:  $mainYellow;
            padding: 3px 5px;

            span {
              display: block;
              position: relative;
              padding-left: 8px;

              &:before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 8px;
                margin: auto;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: black;
              }
            }
          }
        }

        &.-case-1 {
          background: url('@/assets/img/reviews/bgs/case_1.jpg') center no-repeat;
          background-size: cover;
        }
      }

      &__avatar {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        margin: auto;
        border: 3px solid $mainYellow;

        img {
          width: 100%;

        }
      }

      &__text {
        color: black;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
      }
    }

    &__name {
      text-align: center;
      //color: grey;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-weight: 600;
      border-bottom: 2px solid;
      display: inline-block;

    }

    &__text {
      font-size: 20px;
      margin-bottom: 15px;
      text-align: left;
      font-weight: 200;

      b {
        font-weight: 600;
      }
    }

    &__bottom {
      font-weight: 600;
    }
  }
</style>
