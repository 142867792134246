<template>
  <div class="documents">
    <Carousel>
      <Slide v-for="(doc, index) in gallery" :key="doc.name">
        <img
          class="documents__photo" :src="require(`@/assets/img/docs/${doc.preview}`)"
          :alt="doc.name"
          @click="startFancy(index)"
        >
      </Slide>

      <template #addons>
        <Navigation />
<!--        <Pagination />-->
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default defineComponent({
  name: 'Documents',
  components: {
    Fancybox,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data: () => ({
    gallery: [
      {
        name: 'diplom',
        src: require('@/assets/img/docs/diplom.jpg'),
        preview: 'diplom-preview.jpg',
      },
      {
        name: 'certificate-1',
        src: require('@/assets/img/docs/certificate-1.png'),
        preview: 'certificate-1-preview.jpg'
      },
      {
        name: 'certificate-2',
        src: require('@/assets/img/docs/certificate-2.png'),
        preview: 'certificate-2-preview.jpg'
      },
    ]
  }),
  methods: {
    startFancy(index) {
      Fancybox.show(this.gallery, {}).jumpTo(index, { friction: 0 })
    }
  }
})
</script>

<style lang="scss">
.carousel {
  &__prev {
    left: -15px;
  }
  &__next {
    right: -15px;
  }
}
.documents {
  margin-bottom: 30px;
  text-align: center;

  &__photo {
    cursor: pointer;
    width: 85%;

    &:hover {
      opacity: .9;
    }
  }

  &__btn {
    font-size: 16px;
    //text-transform: uppercase;
    height: 40px;
    border: none;
    outline: none;
    //background: white;
    //color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    padding: 0 20px;

    &::before {
      position: absolute;
      content: '';
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      left: -2px;
      top: -2px;
      background: linear-gradient(
          124deg,
          #ff2400,
          #e81d1d,
          #e8b71d,
          #e3e81d,
          #1de840,
          #1ddde8,
          #2b1de8,
          #dd00f3,
          #dd00f3
      );
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      animation: move 20s linear infinite;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
    }

    &::after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      left: 0;
      top: 0;
      border-radius: 10px;
    }
  }

  .title {
    margin-bottom: 30px;
  }

  .link {
    text-align: center;
  }

  &__icon {
    margin-right: 5px;
  }

  span {
    position: relative;
    margin: auto;
    width: 120px;
    text-align: center;
    font-size: 14px;
    //border: 1px solid #d3cc71;
    transition: all .3s;
    cursor: pointer;
    border: 1px solid;
    padding: 10px 15px;

    //&:hover {
    //  filter: contrast(1.1);
    //}
    //
    //&:active {
    //  filter: contrast(0.9);
    //}
    //
    //&::before,
    //&::after {
    //  content: "";
    //  position: absolute;
    //  top: -10px;
    //  left: -10px;
    //  right: -10px;
    //  bottom: -10px;
    //  border: 1px solid #2c3e50;
    //  transition: all .5s;
    //  animation: clippath 3s infinite linear;
    //  border-radius: 10px;
    //}
    //
    //&::after {
    //  animation: clippath 3s infinite -1.5s linear;
    //}
  }

  @keyframes clippath {
    0%,
    100% {
      clip-path: inset(0 0 98% 0);
    }

    25% {
      clip-path: inset(0 98% 0 0);
    }
    50% {
      clip-path: inset(98% 0 0 0);
    }
    75% {
      clip-path: inset(0 0 0 98%);
    }
  }
}
</style>
