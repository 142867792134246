<template>
  <div class="footer">
    <div class="footer__name">Любовь Афанасьева</div>
    <div class="footer__socials">
      <a href="" class="footer__social">
        <img src="@/assets/img/social/ig.svg" alt="youtube icon">
      </a>
      <a href="" class="footer__social">
        <img src="@/assets/img/social/tg.svg" alt="youtube icon">
      </a>
      <a href="" class="footer__social yt">
        <img src="@/assets/img/social/yt.svg" alt="youtube icon">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 20px 15px 30px;
  //background-color: $mainYellow;
  margin-left: -15px;
  margin-right: -15px;

  &__name {
    margin-bottom: 25px;
  }

  &__social {
    margin: 0 7px;

    img {
      width: 24px;
    }
    svg {
      width: 24px;
      height: 32px;
    }
  }
}
</style>
