<template>
  <div class="social">
    <a href="https://t.me/aboutfoodwithlove" target="_blank">
      <img src="@/assets/img/social/tg.gif" alt="telegram icon">
    </a>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'social'
})
</script>

<style lang="scss">
.social {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;

  a {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 72px;
      height: 72px;
      position: absolute;
      top: -6px;
      left: -6px;
      z-index: 0;
    }
  }
}
</style>
