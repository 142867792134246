
import { defineComponent } from 'vue'
import TopBlock from '@/components/sections/TopBlock.vue'
import Preview from '@/components/sections/Preview.vue'
import Reviews from '@/components/sections/Reviews.vue'
import Documents from '@/components/sections/Documents.vue'
import Plate from '@/components/sections/Plate.vue'
import Working from '@/components/sections/Principles.vue'
import Prices from '@/components/sections/Prices/Prices.vue'
import Social from '@/components/sections/Social.vue'
import Footer from '@/components/sections/Footer.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    Plate,
    TopBlock,
    Preview,
    Reviews,
    Documents,
    Working,
    Prices,
    Social,
    Footer
  },
  computed: {
    isPricesVisible() {
      return !this.$route.query?.visit || this.$route.query.visit !== 'personal'
    }
  },
})
