<template>
  <div class="preview">
<!--    <img class="preview__photo" src="@/assets/img/2.jpg" alt="">-->

    <div class="preview__top">
      <div class="preview__title">с Любовью</div>
      <div class="preview__block">
        <div class="preview__title-text">о еде</div>
        <div class="preview__photo">
          <img src="@/assets/img/1.jpg" alt="">
        </div>
      </div>
    </div>

    <div class="preview__text">
      <font-awesome-icon class="preview__text-quote" icon="fa-solid fa-quote-right" />
      <span>
        80% здоровья находится в&nbsp;плоскости питания, физической активности, регуляции биоритмов, восполнении дефицитов. Этот интересный и&nbsp;увлекательный путь восстановления доступен каждому. Меня зовут Любовь, я&nbsp;&mdash; дипломированный нутрициолог. Помогаю внедрить в&nbsp;вашу жизнь здоровые привычки и&nbsp;сбалансированное питание, чтобы быть в&nbsp;ресурсе, получать максимум от&nbsp;каждого дня, наслаждаясь жизнью. Специализируюсь на&nbsp;составлении программ питания под различные задачи: набор/снижение веса, Immunohealth, палео, LCHF, средиземноморский план, mix-diet.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preview"
};
</script>

<style lang="scss">
  .preview {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 -15px;
    margin-bottom: 30px;

    &__top {
      background-color: #d3cc71;
      background-color: $mainYellow;
      padding-top: 15px;
      padding-bottom: 15px;
      //border-bottom: 2px solid;
      text-align: center;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 44px;
      color: $mainBlueTitle;
      line-height: 0.8;
      font-family: 'Cormorant Garamond', sans-serif;
      margin-bottom: 15px;
      position: relative;
      z-index: 1;
    }

    &__block {
      //border: 1px solid;
    }

    &__photo {
      display: block;
      width: 150px;
      height: 150px;
      margin: 0 auto 10px;
      position: relative;

      img {
        width: 100%;
        position: relative;
        z-index: 1;
      }

      &:before {
        display: block;
        content: '';
        width: 170px;
        height: 190px;
        background-color: #fff;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &__title-text {
      font-size: 20px;
      color: black;
      margin-bottom: 3px;
      position: relative;
      z-index: 1;
    }

    &__text {
      font-size: 15px;
      text-align: left;
      position: relative;
      padding-left: 30px;
      padding-right: 15px;

      &-quote {
        position: absolute;
        top: -40px;
        left: 10px;
        opacity: .1;
        font-size: 80px;
        z-index: 1;
        //display: none;
      }

      span {
        position: relative;
        z-index: 2;
      }
    }
  }
</style>
