<template>
  <div class="comparison">
    <div class="title">За 60 дней</div>

    <ul>
      <li v-for="li in ul">
        <img src="@/assets/img/icons/check-mark.svg" alt="">
        {{ li.text }}
      </li>
    </ul>

    <div class="comparison__plate">
      <img
        src="@/assets/img/plate.png"
        alt=""
      >

      <div id="circle">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 200 200"
             xml:space="preserve"
        >
          <defs>
            <path id="circlePath" d="M 100, 100 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0" />
          </defs>
            <use xlink:href="#circlePath" fill="none" />
            <text fill="#182aa7">
            <textPath xlink:href="#circlePath">• be energy • be energy • be energy •</textPath>
          </text>
        </svg>
      </div>
    </div>

    <p>Какие состояния корректируются<br>изменением рациона:</p>

    <ul class="bg-icon__question">
      <li v-for="li in ol">
        {{ li.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Plate',
  data() {
    return {
      ul: [
        { text: '«Прокачаешь» свою тарелку' },
        // { text: 'Восполнишь дефициты витаминов' },
        { text: 'Станешь энергичной' },
        { text: 'Забудешь о дискомфорте после еды' },
        // { text: 'Научишься слушать свой организм и понимать его' },
        // { text: 'Найдешь комфортный режим питания' },
        // { text: 'Будешь в ресурсе и в отличном настроении в ПМС' },
        // { text: 'Полюбишь своё тело' },
      ],
      ol: [
        { text: 'АИТ/ Гипотиреоз/ Гипертериоз' },
        { text: 'СПКЯ/ Инсулинорезистентность' },
        { text: 'Пищевая непереносимость/ Аллергия' },
        { text: 'СИГР/ СИБР' },
        { text: 'Акне/ Псориаз' },
      ],
    }
  }
})
</script>

<style lang="scss">
@keyframes rotateAnimation {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
.comparison {
  margin-bottom: 30px;

  &__slider {
    transition: box-shadow 200ms ease-in-out;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__block {
    margin-bottom: 15px;
  }

  ul {
    //padding: 25px 15px;
    margin-bottom: 25px;
    li {
      position: relative;
      list-style: none;
      padding: 0;

      img {
        width: 24px;
      }

      &:before {
        display: none;
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        margin: auto;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: black;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__plate {
    position: relative;
    padding: 20px 0;
    //margin-bottom: 20px;
    overflow: hidden;
    margin-bottom: 20px;

    img {
      width: 200px;
      height: 200px;
      margin: auto;
      display: block;
    }
  }

  #circle {
    width: 330px;
    height: 330px;
    padding-bottom: 0;
    overflow: visible;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    text {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      color: black;
      letter-spacing: .32em;
    }

    svg {
      text {
        transform-origin: 50% 50%;
        animation-name: rotateAnimation;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
}
</style>
