<template>
  <div class="price__block">
    <div>
      <div class="price__name">{{ title }}</div>
      <div class="price__text" v-html="text" />
    </div>
    <div class="price__bottom" v-html="`${price} руб.`" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Price',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
  }
})
</script>
