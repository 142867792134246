<template>
  <div class="price">
    <div class="title">Price list</div>

    <div class="price__top" />

    <div class="price__blocks">
      <Price
        v-for="(price, priceIndex) in prices"
        :key="priceIndex"
        :title="price.title"
        :text="price.text"
        :price="price.value"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Price from "@/components/sections/Prices/Price"

export default defineComponent({
  name: 'Prices',
  components: {
    Price
  },
  data() {
    return {
      prices: [
        // {
        //   title: 'Экспресс',
        //   text: 'Консультация по БАД&mdash;<br>самые эффективные формы, аналоги с iHerb, зарубежные сайты где можно сделать заказ.<br><br>Длительность 30 минут.',
        //   value: '1 500'
        // },
        {
          title: 'Разовая',
          text: 'Консультация подходит для тех, кто в теме правильного питания и хочет задать вопросы таргетированно.<br>Список вопросов высылаете заранее.<br><br>Длительность 60 минут.',
          value: '2 500'
        },
        {
          title: 'Протокол',
          text: 'Меню на&nbsp;7&nbsp;дней с&nbsp;расчетом БЖУ по&nbsp;результатам генетических непереносимостей (gluten/ lactose free).<br>Ссылки на&nbsp;продукты: маркетплейсы, фермеры, супермаркеты.',
          value: '4 000'
        },
        {
          title: 'Ведение 2 месяца',
          text: 'Первичная консультация&nbsp;&mdash; 60-90&nbsp;минут. Меню на&nbsp;10&nbsp;дней (продуктовая матрица c&nbsp;рецептами). Схема БАД. С&nbsp;обратной связью по&nbsp;Telegram или WhatsApp.',
          value: '30 000'
        },
      ]
    }
  }
})
</script>

<style lang="scss">
  .price {
    margin-bottom: 30px;

    .title {
      text-transform: uppercase;
    }

    &__top {
      height: 55px;
      background: #d3cc71;
      background: $mainYellow;
      margin: -29px -15px -15px;
    }

    &__blocks {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      overflow: auto;
    }

    &__block {
      flex: 1 0 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid black;
      //border: 1px solid #eef0f1;
      padding: 20px 15px;
      text-align: center;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &__name {
      text-align: center;
      //color: grey;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-weight: 600;
      border-bottom: 2px solid;
      display: inline-block;

    }

    &__text {
      font-size: 14px;
      margin-bottom: 15px;
      text-align: left;

      b {
        font-weight: 600;
      }
    }

    &__bottom {
      font-weight: 600;
    }
  }
</style>
