import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Preview = _resolveComponent("Preview")!
  const _component_Documents = _resolveComponent("Documents")!
  const _component_Working = _resolveComponent("Working")!
  const _component_Reviews = _resolveComponent("Reviews")!
  const _component_Plate = _resolveComponent("Plate")!
  const _component_Prices = _resolveComponent("Prices")!
  const _component_Social = _resolveComponent("Social")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Preview),
    _createVNode(_component_Documents),
    _createVNode(_component_Working),
    _createVNode(_component_Reviews),
    _createVNode(_component_Plate),
    (_ctx.isPricesVisible)
      ? (_openBlock(), _createBlock(_component_Prices, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Social)
  ]))
}