<template>
  <div class="working">
    <div class="title">
      Принципы
    </div>

    <ul>
      <li>
        Придерживаюсь холистического подхода к&nbsp;здоровью&nbsp;&mdash; работаю с&nbsp;причиной, а&nbsp;не&nbsp;с&nbsp;симптомами.
      </li>
      <li>
        Программа питания составляется на&nbsp;основе имеющихся анализов, рекомендаций врача, дневника питания и&nbsp;образа жизни.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Working'
}
</script>

<style lang="scss">
  .working {
    position: relative;
    margin-bottom: 30px;

    ul {
      counter-reset: list;
      margin: 0 0 0 -15px;

      li {
        position: relative;
        padding-left: 30px;
        font-size: 15px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &:before {
          position: absolute;
          counter-increment: list;
          content: counter(list);
          opacity: 0.12;
          font-size: 60px;
          z-index: 1;
          font-weight: 500;
          pointer-events: none;
        }

        &:nth-child(odd) {
          &:before {
            font-size: 100px;
            line-height: 100px;
            top: -35px;
            left: 20px;
          }
        }
        &:nth-child(even) {
          &:before {
            font-size: 110px;
            line-height: 100px;
            bottom: -5px;
            right: 20px;
          }
        }
      }
    }
  }
</style>
