import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import {
  faQuoteRight, faCheck, faPaperclip, faRubleSign, faCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  faHeart
} from '@fortawesome/free-regular-svg-icons'

import '@/assets/styles/normalize.scss'
import '@/assets/styles/main.scss'
import '@fancyapps/ui/dist/fancybox.css'

library.add(faQuoteRight, faCheck, faPaperclip, faRubleSign, faCircle, faHeart)

createApp(App)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
